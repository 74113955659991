import React from "react";
import {graphql, Link} from 'gatsby';
import { Seo } from "../../components/Seo";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '../../components/Button';
import {getButtonColor} from '../../utils/getButtonColor';
import clsx from "clsx";
import Block from '../../components/Landing/Block';

const RadsLanding = ({data}) => {
  const prismicData = data.prismicRadsLp.data;
  return (
    <main>
      <section className="py-24"
        style={{backgroundColor: prismicData.background_color}}>
        <div className="xl:container mx-auto px-4 text-center">
          <div className="mx-auto mb-12">
            <Link to="/">
              <GatsbyImage image={getImage(prismicData.sourci_logo)}
                alt={prismicData.sourci_logo.alt || ''}
                className="mb-12" />
            </Link>
            <h1 className="font-black text-4xl lg:text-6xl mb-6"
              style={{color: prismicData.title_color}}>
              {prismicData.title.text}
            </h1>
            <p className="text-4xl mb-8 max-w-[800px] mx-auto" 
              style={{color: prismicData.caption_color}}>{prismicData.caption.text}</p>
            <div className="grid lg:grid-cols-2 gap-x-8 text-left max-w-[650px] mx-auto mb-12">
              <ul className="text-xl text-white font-bold">
                {prismicData.services__one_.map((i,k) => (
                  <li key={k}
                    className="list-marker before:inline-block before:w-[1em] before:text-[1.7rem] before:text-sourci-pink">
                      {i.item}
                    </li>
                ))}
              </ul>
              <ul className="text-xl text-white font-bold">
                {prismicData.services__two_.map((i,k) => (
                  <li key={k}
                    className="list-marker before:inline-block before:w-[1em] before:text-[1.7rem] before:text-sourci-pink">
                      {i.item}
                    </li>
                ))}
              </ul>
            </div>
            <p className="font-black text-3xl lg:text-4xl text-black mb-8">AS SEEN IN</p>
            <div className="lg:max-w-[970px] rounded-3xl bg-white p-10 mx-auto text-left mb-12">
              <div className="grid lg:grid-cols-3 gap-x-8 gap-y-8">
                {prismicData.as_seen_in_logos.map((i,k) => (
                  <div key={k}
                    className="flex items-center justify-center">
                    <GatsbyImage image={getImage(i.logo)}
                      alt={i.logo.alt || ''}
                      className="mx-auto max-w-[150px]" />
                  </div>
                ))}
              </div>
            </div>

            <Button type="external"
              to={prismicData.button_link.url}
              className={clsx('transition-colors font-black text-3xl w-full lg:w-auto', getButtonColor(prismicData.button_style))}
              label={prismicData.button_text}
              padding="px-16 py-3" />
          </div>
        </div>
      </section>
      {prismicData.body.map((i,k) => (
        <Block key={k} {...i} />
      ))}
    </main>
  )
}

export default RadsLanding;

export const query = graphql`
  query {
    prismicRadsLp {
      data {
        as_seen_in_logos {
          logo {
            alt
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        background_color
        body {
          ... on PrismicRadsLpDataBodyAbout {
            id
            items {
              add_paragraph_break
              color
              text {
                html
              }
            }
            primary {
              background_color
              desktop_section_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title: heading {
                text
              }
              heading_color
              image_position
              section_image__mobile_ {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              subheading {
                text
              }
              subheading_color
            }
            slice_type
          }
          ... on PrismicRadsLpDataBodyImageGrid {
            id
            slice_type
            items {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              paragraph_one {
                html
              }
              paragraph_one_color
              paragraph_two {
                html
              }
              paragraph_two_color
              title {
                text
              }
            }
            primary {
              background_color
              column_alignment
              cta_button_link {
                url
                type
              }
              cta_button_style
              cta_button_text
              desktop_grid
              title: heading {
                text
              }
              heading_color
              heading_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              mobile_grid
              paragraph {
                html
              }
              subheading {
                text
              }
              subheading_color
            }
          }
          ... on PrismicRadsLpDataBodyMediaLibrary {
            id
            slice_type
            items {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background_color1
              desktop_columns
              heading
              heading_color
              mobile_columns
            }
          }
        }
        button_link {
          url
        }
        button_style
        button_text
        caption {
          text
        }
        caption_color
        meta_description
        meta_title
        services__one_ {
          item
        }
        services__two_ {
          item
        }
        services_list_color
        services_list_marker_color
        sourci_logo {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        title {
          text
        }
        title_color
      }
    }
  }
`

export function Head({data}) {
  const prismicData = data.prismicRadsLp.data;
  return (
    <Seo title={prismicData.meta_title}
      description={prismicData.meta_description}
      canonical="/lp/rd" />
  )
}